<template>
<div>
  <ListagemMailsComponents></ListagemMailsComponents>
</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ListagemMailsComponents from './components/ListagemMailsComponents'

export default {
  components: {
    ListagemMailsComponents,
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Configurações', route: 'configuracoes' },
      { title: 'Emails' },
    ])

    // this.$refs.snackbar.show('teste', 'teste 2', 'success', 30000, true)
  },
}
</script>

<style scoped>

</style>
