<template>
  <v-dialog v-model="model" max-width="800">
    <custom-snackbar
        ref="snackbar"
    />
    <v-form ref="form">
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Atualizar Emails</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12 my-0 p-0">
                <v-data-table
                    fixed-header
                    :headers="headers"
                    :items="itens"
                    :items-per-page="-1"
                    hide-default-footer
                    dense
                >
                  <template v-slot:[`item.minus`]="{ item }">
                    <v-icon
                        dark
                        color="red"
                        slot="prepend"
                        @click="removeEmail(item.email, item.empresa_id)"
                    >
                      mdi-minus
                    </v-icon>
                  </template>
                  <template v-slot:[`item.email`]="{ item }">
                    <v-text-field v-model="item.email" label="Email" type="email" required/>
                  </template>
                  <template v-slot:[`item.empresa`]="{ item }">
                    <EmpresasSelect v-model="item.empresa_id" mostra-todos-label/>
                  </template>
                  <template v-slot:[`item.producao`]="{ item }">
                    <ProducoesSelect v-model="item.producoes_ids" :empresa="item.empresa_id" multiple  mostra-todos-label/>
                  </template>
                </v-data-table>
                <v-btn
                    icon
                    class="ml-1"
                    color="dark d-flex flex-row-reverse"
                    @click="adicionaEmail"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                    icon
                    class="ml-1"
                    color="dark d-flex flex-row-reverse"
                    @click="habilitaRemocoes = !habilitaRemocoes"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="model = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="confirmarAlteracaoEmails"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    mail: Object,
  },
  data: () => {
    return {
      itens: [],
      habilitaRemocoes: false,
    }
  },
  mounted () {
    this.atualizaItens()
  },
  watch: {
    mail () {
      this.atualizaItens()
    },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    headers () {
      const headers = [
        { text: '', value: 'minus', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Empresa', value: 'empresa', sortable: false },
        { text: 'Produção', value: 'producao', sortable: false },
      ]

      if (!this.habilitaRemocoes || this.itens.length === 1) {
        headers.shift()
      }

      return headers
    },
  },
  methods: {
    atualizaItens () {
      const emailsList = []
      const emails = this.$array.groupBy(this.mail.emails, (item) => item.email + '-' + item.empresa_id)

      emails.forEach((itens) => {
        emailsList.push({
          email: itens[0].email,
          empresa_id: itens[0].empresa_id,
          producoes_ids: itens
            .filter((item) => item.producao_id)
            .map((item) => item.producao_id),
        })
      })

      console.log(emailsList)

      this.itens = emailsList
    },
    removeEmail (email, empresaId) {
      this.itens = this.itens.filter((item) => {
        return item.email !== email || item.empresa_id !== empresaId
      })
    },
    adicionaEmail () {
      this.itens.push({
        email: '',
        empresa_id: null,
        producoes_ids: [],
      })
    },
    confirmarAlteracaoEmails () {
      if (!this.$refs.form.validate()) {
        return false
      }

      this.model = false

      const emails = this.itens
        .filter((item) => item.email.length > 0)
        .flatMap(function (item) {
          const itensProducoes = item.producoes_ids.map((producao) => {
            return {
              email: item.email,
              empresa_id: item.empresa_id === '' ? null : item.empresa_id,
              producao_id: producao === '' ? null : producao,
            }
          })

          if (itensProducoes.length > 0) {
            return itensProducoes
          }

          return [{
            email: item.email,
            empresa_id: item.empresa_id === '' ? null : item.empresa_id,
            producao_id: null,
          }]
        })

      if (emails.length === 0) {
        return false
      }

      this.$refs.snackbar.show('Requisitando', 'Vinculando emails de envio', 'default', -1, true)

      const form = {
        emails,
      }

      ApiService
        .put('/notification/mail/' + this.mail.id + '/emails', form)
        .then((res) => {
          this.$refs.snackbar.close()

          if (!res.data.success) {
            this.$refs.snackbar.show('Não Vinculado', res.data.mensagem, 'warning', 3000, false)
            return
          }

          this.$emit('atualizado')
          this.$refs.snackbar.show('Vinculado', res.data.mensagem, 'success', 3000, false)
        })
        .catch(() => {
          this.$emit('atualizado')
          this.$refs.snackbar.close()
          this.$refs.snackbar.show('Não Vinculado', 'Problema na comunicação', 'danger', 3000, false)
        })
    },
  },
}
</script>
