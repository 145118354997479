import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VCardText,{staticClass:"py-0 px-0 px-md-4 py-md-4"},[_c(VDataTable,{ref:"tabelaMails",attrs:{"dense":"","show-expand":"","single-expand":"","item-key":"id","expanded":_vm.expanded,"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"no-data-text":_vm.noData,"items-per-page":_vm.$dataTableConfig.getItemsPerPageCustom(),"footer-props":_vm.$dataTableConfig.getFooterProps(),"loading-text":_vm.$dataTableConfig.getLoadText(),"mobile-breakpoint":0},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); },"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.descricao))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.status)?_c(VIcon,{attrs:{"color":"green","small":""}},[_vm._v("mdi-checkbox-blank-circle")]):_vm._e(),(!item.status)?_c(VIcon,{attrs:{"color":"red","small":""}},[_vm._v("mdi-checkbox-blank-circle")]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('VisualizaMailComponents',{attrs:{"mail":item},on:{"atualizado":_vm.getData}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }