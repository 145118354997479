import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('custom-snackbar',{ref:"snackbar"}),_c(VForm,{ref:"form"},[_c(VDataTable,{attrs:{"item-key":"id","dense":"","headers":_vm.headersItens,"items":_vm.itens,"items-per-page":10,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div')]}}],null,true)}),_c('div',{staticClass:"mb-5 mt-3"},[_c(VBtn,{attrs:{"color":"info mr-2","small":"","dark":""},on:{"click":_vm.sincronizaEmails}},[_vm._v(" Atualiza Emails ")])],1)],1),_c('hr',{staticClass:"my-3"}),_c('AlterarEmailsComponent',{attrs:{"mail":_vm.mail},on:{"atualizado":function($event){return _vm.$emit('atualizado')}},model:{value:(_vm.showSincronizaEmails),callback:function ($$v) {_vm.showSincronizaEmails=$$v},expression:"showSincronizaEmails"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }