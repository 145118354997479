import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('custom-snackbar',{ref:"snackbar"}),_c(VForm,{ref:"form"},[_c(VCard,[_c(VToolbar,{attrs:{"color":"info","dark":""}},[_c('span',{staticClass:"headline"},[_vm._v("Atualizar Emails")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12 my-0 p-0"}},[_c(VDataTable,{attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.itens,"items-per-page":-1,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.minus",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"slot":"prepend","dark":"","color":"red"},on:{"click":function($event){return _vm.removeEmail(item.email, item.empresa_id)}},slot:"prepend"},[_vm._v(" mdi-minus ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c(VTextField,{attrs:{"label":"Email","type":"email","required":""},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})]}},{key:"item.empresa",fn:function(ref){
var item = ref.item;
return [_c('EmpresasSelect',{attrs:{"mostra-todos-label":""},model:{value:(item.empresa_id),callback:function ($$v) {_vm.$set(item, "empresa_id", $$v)},expression:"item.empresa_id"}})]}},{key:"item.producao",fn:function(ref){
var item = ref.item;
return [_c('ProducoesSelect',{attrs:{"empresa":item.empresa_id,"multiple":"","mostra-todos-label":""},model:{value:(item.producoes_ids),callback:function ($$v) {_vm.$set(item, "producoes_ids", $$v)},expression:"item.producoes_ids"}})]}}],null,true)}),_c(VBtn,{staticClass:"ml-1",attrs:{"icon":"","color":"dark d-flex flex-row-reverse"},on:{"click":_vm.adicionaEmail}},[_c(VIcon,[_vm._v("mdi-plus")])],1),_c(VBtn,{staticClass:"ml-1",attrs:{"icon":"","color":"dark d-flex flex-row-reverse"},on:{"click":function($event){_vm.habilitaRemocoes = !_vm.habilitaRemocoes}}},[_c(VIcon,[_vm._v("mdi-minus")])],1)],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.model = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.confirmarAlteracaoEmails}},[_vm._v(" Salvar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }