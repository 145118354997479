<template>
  <v-card class="mb-5">
    <v-card-text class="py-0 px-0 px-md-4 py-md-4">
      <v-data-table
            ref="tabelaMails"
            dense
            show-expand
            single-expand
            item-key="id"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            :expanded.sync="expanded"
            :headers="headers"
            :items="data"
            :loading="loading"
            :no-data-text="noData"
            :items-per-page="$dataTableConfig.getItemsPerPageCustom()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :mobile-breakpoint="0"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              />
            </template>

            <template v-slot:[`item.descricao`]="{ item }">
              <span>{{ item.descricao }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <span>
                <v-icon v-if="item.status" color="green" small>mdi-checkbox-blank-circle</v-icon>
                <v-icon v-if="!item.status" color="red" small>mdi-checkbox-blank-circle</v-icon>
              </span>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <VisualizaMailComponents :mail="item" @atualizado="getData"></VisualizaMailComponents>
              </td>
            </template>
          </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import ApiService from '@/core/services/api.service'

import VisualizaMailComponents from './VisualizaMailComponents'

export default {
  components: {
    VisualizaMailComponents,
  },
  data: () => ({
    // General
    loading: false,
    noData: 'Nenhuma informação encontrada',

    headers: [
      { value: 'data-table-expand', align: 'right p-0', class: 'sticky-header custom-table--header' },
      { text: 'Email', value: 'descricao', class: 'sticky-header custom-table--header' },
      { text: 'Status', value: 'status', class: 'sticky-header custom-table--header', sortable: false },
    ],
    // Form Data
    data: [],
    // Expand Data Table
    expanded: [],
  }),

  mounted () {
    this.getData()
  },

  methods: {
    getData () {
      if (this.loading) {
        return
      }

      this.loading = true
      this.expanded = []

      ApiService
        .get('/notification/mail')
        .then((res) => res.data)
        .then((content) => {
          this.data = content.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    validDate (value) {
      if (value == null) {
        return '-'
      }

      if (value < '1901-01-01') {
        return '-'
      }

      return this.$stringFormat.dateISOToBRL(value)
    },

    getColor (item) {
      if (item.codigo_sc === '0') {
        return 'bg-purple'
      }

      return ([
        'bg-pendente',
        'bg-pendente-cotacao',
        'bg-urgente',
        'bg-cotada',
        'bg-urgente',
        'bg-aprovada',
        'bg-aprovada',
        'bg-aprovada',
        'bg-aprovada',
        'bg-entregue',
        'bg-entregue',
        'bg-comprada',
        'bg-cotando',
      ])[item.status_code]
    },
  },
}
</script>

<style>
.v-btn--height-fix {
  width: 100%;
  height: 40px;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.v-btn--height-fix {
  width: 100%;
  height: 40px;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.bg-purple {
  background-color: purple;
  color: #fff;
}
.bg-cotando {
  background-color: #1F3853;
  color: #fff;
}
.bg-pendente-cotacao {
  background-color: #3F6883;
  color: #fff;
}
.bg-pendente {
  background-color: #FDD01C;
  color: #000;
}
.bg-urgente {
  background-color: #E36968;
  color: #fff;
}
.bg-aprovada {
  background-color: #0AA699;
  color: #fff;
}
.bg-cotada {
  background-color: #0090D9;
  color: #fff;
}
.bg-comprada {
  background-color: #D1DADE;
  color: #000;
}
.bg-entregue {
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
}
.label.label-lg.label-inline {
  width: 100%;
}
.custom-switch,
.custom-switch .v-input__slot,
.custom-switch label {
  padding: 0 !important;
  margin: 0 !important;
}
.custom-switch .v-input--selection-controls__ripple {
  display: none !important;
}
</style>
