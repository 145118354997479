<template>
  <v-container>
    <custom-snackbar
        ref="snackbar"
    />
    <v-form ref="form">
      <v-data-table
          item-key="id"
          dense
          :headers="headersItens"
          :items="itens"
          :items-per-page="10"
          :mobile-breakpoint="0"
      >

        <template v-slot:progress>
          <v-progress-linear
              absolute
              indeterminate
              color="green"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div>
          </div>
        </template>
      </v-data-table>

      <div class="mb-5 mt-3">
        <v-btn
            color="info mr-2"
            small
            dark
            @click="sincronizaEmails"
        >
          Atualiza Emails
        </v-btn>
      </div>

    </v-form>
    <hr class="my-3"/>
    <AlterarEmailsComponent v-model="showSincronizaEmails" :mail="mail" @atualizado="$emit('atualizado')"/>
  </v-container>
</template>

<script>
import AlterarEmailsComponent from './dialogs/AlterarEmailsComponent'

export default {
  components: {
    AlterarEmailsComponent,
  },
  props: {
    mail: {
      type: Object,
    },
  },
  data: () => ({
    showSincronizaEmails: false,
  }),

  computed: {
    itens () {
      return this.mail.emails
    },
    headersItens () {
      return [
        { text: 'Email', value: 'email' },
        { text: 'Empresa', value: 'empresa' },
        { text: 'Produção', value: 'producao' },
        { value: 'actions', align: 'right' },
      ]
    },
  },
  methods: {
    sincronizaEmails () {
      this.showSincronizaEmails = true
    },
  },
}
</script>
